import { gql } from '@apollo/client';

export const getUserAppointmentsQuery = gql`
  query getUserAppointments($filters: O8FiltersInput) {
    AdvocateAppointmentsResults(filters: $filters) {
      results {
        externalIdentifier
        ownerId
        startDateTime
        endDateTime
        subject
        leadExternalIdentifier
      }
    }
  }
`;

export const getReprensativeInfoQuery = gql`
  query getReprensativeInfoQuery($filters: O8RootFiltersInput) {
    userProfileResults(filters: $filters) {
      results {
        email
        name
        id
        wtLicensedStates
        advocateLevel
        approvedProducts
        licensedStates
        talkdeskUserId
        timezone
        wtSkills {
          name
        }
      }
    }
  }
`;

export const getScheduledTimeQuery = gql`
  query getScheduledTimeResults($filters: O8RootFiltersInput) {
    advocatePaidTimesResultsV2(filters: $filters) {
      results {
        email
        startWorkingDay
        weekNumber
        day
        month
        year
        scheduledSlots {
          slots
        }
        workedTime
        userInfo {
          name
        }
      }
    }
  }
`;

export const getCallLogsQuery = gql`
  query getCallLogs($filters: O8RootFiltersInput) {
    callLogResults(filters: $filters) {
      results {
        externalIdentifier
        hangUpReason
        startTime
        endTime
      }
    }
  }
`;

export const getPaidTimeAndCallLogsQuery = gql`
  query getPaidTimeAndCallLogsResults($paidTimeFilters: O8RootFiltersInput) {
    advocatePaidTimesResultsV2(filters: $paidTimeFilters) {
      results {
        email
        startWorkingDay
        endWorkingDay
        weekNumber
        day
        month
        year
        scheduledSlots {
          slots
        }
        workedTime
        userInfo {
          name
        }
        callLogs {
          externalIdentifier
          hangUpReason
          startTime
        }
      }
    }
  }
`;

export const getProfitAndLossMetricsQuery = gql`
  query getProfitAndLossMetricsResults($filters: O8RootFiltersInput) {
    profitAndLossMetricsResults(filters: $filters) {
      results {
        advocateEmail
        periodStart
        periodEnd
        periodType
        reportingPod
        leadCost
        activityCost
        leadCount
        protectionCount
        expectedRevenue
        totalFYC
      }
    }
  }
`;
