import { AdvocatePaidTime, FilterComparators, O8RootQueryFilter } from 'common.interfaces';
import { DateTime } from 'luxon';

export const getDayFiltersForWholeWeek = (startDate: string): O8RootQueryFilter<AdvocatePaidTime>[] => {
  const filters: O8RootQueryFilter<AdvocatePaidTime>[] = [];
  const startDateTime = DateTime.fromISO(startDate);
  let isMonthChanged = false;
  let isYearChanged = false;

  for (let i = 0; i < 7; i++) {
    filters.push({
      and: [
        {
          key: 'day',
          comparator: FilterComparators.EQUAL,
          value: startDateTime.plus({ day: i }).day,
        },
        {
          key: 'month',
          comparator: FilterComparators.EQUAL,
          value: isMonthChanged ? startDateTime.plus({ month: 1 }).month : startDateTime.month,
        },
        {
          key: 'year',
          comparator: FilterComparators.EQUAL,
          value: isYearChanged ? startDateTime.plus({ year: 1 }).year : startDateTime.year,
        },
      ],
    } satisfies O8RootQueryFilter<AdvocatePaidTime>);

    isMonthChanged ||= startDateTime.plus({ day: i }).day === startDateTime.endOf('month').day;
    isYearChanged ||= isMonthChanged && startDateTime.month === 12;
  }
  return filters;
};

export const convertSelectedMonthToPeriodStartDateFormat = (selectedMonth: Date): string => {
  return DateTime.utc()
    .set({
      year: selectedMonth.getFullYear(),
      month: selectedMonth.getMonth() + 1,
      day: 1,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toISO();
};
