import { inject, Injectable } from '@angular/core';
import {
  AdvocatePaidTime,
  CallLog,
  FilterComparators,
  O8RootQueryFilter,
  ProfitAndLossMetrics,
  RepresentativeProfile,
  SF_REPRESENTATIVE_ROLE_ID,
  UserAppointment,
} from 'common.interfaces';
import { map, Observable } from 'rxjs';
import { AdvocateApi } from './advocate.api';
import { DateTime } from 'luxon';
import { sortAscBy, withoutTrailingSlash } from '@origin8-web/o8-utils/format';
import { O8ConfigService } from '@origin8-web/core-ui/config';
import { Protec8Env } from '../../environments';
import { BrowserExtApiService } from '@origin8-web/core-ui/browser-ext-api';
import {
  convertSelectedMonthToPeriodStartDateFormat,
  getDayFiltersForWholeWeek,
} from './advocate.service-gateway.utils';

@Injectable({
  providedIn: 'root',
})
export class AdvocateServiceGateway {
  private readonly advocateApi = inject(AdvocateApi);
  private readonly configService = inject<O8ConfigService<Protec8Env>>(O8ConfigService);
  private readonly browserExtApiService = inject(BrowserExtApiService);

  getUserScheduledTimeForNextWeek(email: string): Observable<Date[]> {
    const weekNumber = DateTime.now().weekNumber + 1;
    return this.advocateApi.getUserScheduledTimeForWeek(email, weekNumber);
  }

  getUserLatestCallOfTheDay(email: string): Observable<CallLog | null> {
    const filters: O8RootQueryFilter<CallLog> = {
      and: [
        {
          key: 'advocateEmail',
          comparator: FilterComparators.EQUAL,
          value: email,
        },
        {
          key: 'endTime',
          comparator: FilterComparators.GREATER,
          value: DateTime.now().startOf('day').toJSDate(),
        },
      ],
      sort: [
        {
          key: 'endTime',
          order: -1,
        },
      ],
      limit: 1,
    };
    return this.advocateApi.getCallLogs(filters).pipe(
      map((callLogs: CallLog[]) => {
        return callLogs[0] ?? null;
      }),
    );
  }

  getAllRepresentativeProfiles(): Observable<RepresentativeProfile[]> {
    const filters: O8RootQueryFilter<RepresentativeProfile> = {
      and: [
        {
          key: 'isActive',
          comparator: FilterComparators.EQUAL,
          value: true,
        },
        {
          key: 'sfUserRoleId',
          comparator: FilterComparators.EQUAL,
          value: SF_REPRESENTATIVE_ROLE_ID,
        },
      ],
    };
    return this.advocateApi.getRepresentativeProfiles(filters).pipe(
      map((representativeProfiles: RepresentativeProfile[]) => {
        return representativeProfiles;
      }),
    );
  }

  getAllAdvocatesScheduledTimesForToday(): Observable<Array<Pick<AdvocatePaidTime, 'email' | 'scheduledSlots'>>> {
    const filters: O8RootQueryFilter<AdvocatePaidTime> = {
      and: [
        {
          key: 'day',
          comparator: FilterComparators.EQUAL,
          value: DateTime.now().day,
        },
        {
          key: 'month',
          comparator: FilterComparators.EQUAL,
          value: DateTime.now().month,
        },
        {
          key: 'year',
          comparator: FilterComparators.EQUAL,
          value: DateTime.now().year,
        },
      ],
    };
    return this.advocateApi.getAdvocatePaidTimes(filters).pipe(
      map((advocatePaidTimes: AdvocatePaidTime[]) => {
        return advocatePaidTimes.map((advocatePaidTime) => {
          return {
            email: advocatePaidTime.email,
            scheduledSlots: advocatePaidTime.scheduledSlots,
          };
        });
      }),
    );
  }

  getAllAdvocatesPaidTimeInfoForWeek(filters: {
    startDate: string;
    endDate: string;
    emails: string[];
  }): Observable<AdvocatePaidTime[]> {
    const paidTimeFilters: O8RootQueryFilter<AdvocatePaidTime> = {
      and: [
        {
          key: 'email',
          comparator: FilterComparators.IN,
          value: filters.emails,
        },
      ],
      or: getDayFiltersForWholeWeek(filters.startDate),
    };
    return this.advocateApi.getAdvocatePaidTimesAndCallLogs(paidTimeFilters);
  }

  getUserAppointmentsByOwnerIdForToday(userId: string): Observable<UserAppointment[]> {
    const startOfDay = DateTime.now().startOf('day').toISO() as string;
    const endOfToday = DateTime.now().endOf('day').toISO() as string;
    const filters: O8RootQueryFilter<UserAppointment> = {
      and: [
        {
          key: 'ownerId',
          comparator: FilterComparators.EQUAL,
          value: userId,
        },
        {
          key: 'startDateTime',
          comparator: FilterComparators.GREATER,
          value: startOfDay,
        },
        {
          key: 'startDateTime',
          comparator: FilterComparators.LOWER,
          value: endOfToday,
        },
      ],
    };
    return this.advocateApi.getUserAppointments(filters).pipe(
      map((userAppointments: Array<UserAppointment & { startDateTime: Date; endDateTime: Date }>) => {
        const upcomingUserAppointments: UserAppointment[] = userAppointments.map((appointment) => {
          return {
            ...appointment,
            startDateTime: DateTime.fromJSDate(appointment.startDateTime),
            endDateTime: DateTime.fromJSDate(appointment.endDateTime),
          };
        });
        return sortAscBy('startDateTime')(upcomingUserAppointments) as UserAppointment[];
      }),
    );
  }

  openAppointmentInSf = (appointment: UserAppointment): void => {
    const sfBaseUrl = withoutTrailingSlash(this.configService.get('salesForceBaseUrl'));
    this.browserExtApiService.triggerSfNavigation(appointment.externalIdentifier, sfBaseUrl, 'Event');
  };

  getSfLinkForAppointment = (appointment: UserAppointment): string => {
    const sfBaseUrl = withoutTrailingSlash(this.configService.get('salesForceBaseUrl'));
    return `${sfBaseUrl}/lightning/r/Event/${appointment.externalIdentifier}/view`;
  };

  getRepresentativeProfitAndLossMetricsForMonth(queryFilters: {
    email: string;
    selectedMonth: Date;
  }): Observable<ProfitAndLossMetrics> {
    const filters: O8RootQueryFilter<ProfitAndLossMetrics> = {
      and: [
        {
          key: 'advocateEmail',
          comparator: FilterComparators.EQUAL,
          value: queryFilters.email,
        },
        {
          key: 'periodStart',
          comparator: FilterComparators.EQUAL,
          value: convertSelectedMonthToPeriodStartDateFormat(queryFilters.selectedMonth),
        },
        {
          key: 'reportingPod',
          comparator: FilterComparators.EQUAL,
          value: 'All',
        },
      ],
    };

    return this.advocateApi.getProfitAndLossMetrics(filters).pipe(
      map((profitAndLossMetrics: ProfitAndLossMetrics[]) => {
        return profitAndLossMetrics[0];
      }),
    );
  }
}
